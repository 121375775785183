import React from "react";
import Layout from "../../Layout";
import "./style.scss";
import SEO from "../../seo";

import icon1 from "../../../images/assets/news/icons/icons-01-01.png";
import icon2 from "../../../images/assets/news/icons/icons-01-02.png";
import icon4 from "../../../images/assets/news/icons/icons-01-03.png";
import icon3 from "../../../images/assets/news/icons/icons.png";

export default function ChinmayaVisionProgrammePage() {
  return (
    <Layout>
      <SEO
        title="Chinmaya Vision Programme | Chinmaya Vidyalaya Vasant Vihar"
        description="Chinmaya Vision Program aims at the overall unfoldment and gentle blossoming of the student at the physical, mental, intellectual and spiritual level."
        img="https://www.chinmayavvdelhi.ac.in/images/Blackele/Chinmaya_link_preview.jpg"
        keywords=""
      />
      <div className="visionprogramme">
        <h1 className="heading visionprogramme__heading">
          Chinmaya Vision Programme (CVP)
        </h1>

        <p className="visionprogramme__text1">
          The Chinmaya Vision Programme had its formal beginning in June 1996
          when it was introduced in Chinmaya Vidyalaya, Coimbatore.
          <br />
          <br />
          The Chinmaya Vision Programme is what makes the Chinmaya Education
          Institutions ‘institutions with a difference’. It concretises Gurudev
          Swami Chinmayananda’s vision of education, and was born to enshrine
          the philosophy of education developed by Him in the heart of every
          student to bring out the best in him or her.
        </p>

        <div className="visionprogramme__schlok">
          Yathaa drishti tathaa srishti - As the vision so the world appears to
          us.
        </div>

        <p className="visionprogramme__text2">
          If man’s vision is narrow and disturbed, the world also seems filled
          with strife. If the vision is road and love-filled, so too is his
          world.
          <br />
          <br />
          The child is the focal point of this programme. The programme also
          embraces the school management, teachers and parents. Through them,
          the light of this vision spreads to the society, country, and the
          world at large.
          <br />
          <br />
          This programme has been introduced to representatives of over 2000
          schools and colleges till date, and has gone to 20 states of India
          through more than 500 seminars. It has even crossed the shores of
          India.
        </p>

        <div className="visionprogramme__subsection">
          <img
            className="visionprogramme__subsection__image"
            alt=""
            src={icon1}
          ></img>
          <div className="visionprogramme__subsection__heading">
            Integrated Development
          </div>
          It aims at the overall unfoldment and gentle blossoming of the student
          at the physical, mental, intellectual and spiritual levels of his/ her
          personality.
          <div className="visionprogramme__subsection__subheading">
            <strong>PHYSICAL DEVELOPMENT</strong>
          </div>
          <p>
            It implies good health and proper growth of students leading to the
            optimum expression of their overall well-being. It includes physical
            fitness, nutrition, hygiene, personal grooming, health education,
            health assessment, prevention of diseases and their treatment.
          </p>
          <div className="visionprogramme__subsection__subheading">
            <strong>MENTAL DEVELOPMENT</strong>
          </div>
          <p>
            It aims at making the student an emotionally balanced person. It
            thereby helps him / her to live in harmony with himself / herself
            and the world. It includes emotional expansion, handling emotions,
            handling relationships, gender specific education, mental health
            assessment and counselling.
          </p>
          <div className="visionprogramme__subsection__subheading">
            <strong>INTELLECTUAL DEVELOPMENT</strong>
          </div>
          <p>
            Man has infinite intellectual potential. Under this head, CVP aims
            to help the student manifest his / her inherent intellectual
            capacities and enhance existing ones. It includes intellectual
            kindling, independent thinking, management skills, aesthetics,
            intellectual assessment and guidance.
          </p>
          <div className="visionprogramme__subsection__subheading">
            <strong>SPIRITUAL DEVELOPMENT</strong>
          </div>
          <p>
            Man is potentially divine. Spiritual Development enables the student
            to discover his / her inherent divinity and manifest it. It is
            achieved through value education, value based education, philosophy,
            meditation techniques, yoga etc.
          </p>
        </div>

        <div className="visionprogramme__subsection">
          <img
            className="visionprogramme__subsection__image"
            alt=""
            src={icon3}
          ></img>
          <div className="visionprogramme__subsection__heading">
            Universal Outlook
          </div>
          Universal Outlook refers to an appreciation, sensitivity and
          commitment to universal issues. After years of mindless pillage of
          nature, the world needs to come back to a universal holistic view of
          life. A universal outlook helps the student view himself / herself as
          responsible citizen of the world (including its burning issues), live
          in harmony with creation (environment education and the synthesis
          between science and religion) as well as become aware of being an
          intrinsic part of the macrocosm.
        </div>

        <div className="visionprogramme__subsection">
          <img
            className="visionprogramme__subsection__image"
            alt=""
            src={icon2}
          ></img>
          <div className="visionprogramme__subsection__heading">
            Indian Culture
          </div>
          Culture is more than just art forms, festivals and rituals. Culture is
          the very way of life of a race and its thinking, which makes it unique
          and special. Under this head the student is given a wide exposure to
          the various aspects of our culture, cultural awareness and
          appreciation of the vast literary, artistic and scientific heritage of
          India, explanations of customs and traditions and cultural motivation
          through hobbies and projects etc.
        </div>
        <div className="visionprogramme__subsection">
          <img
            className="visionprogramme__subsection__image"
            alt=""
            src={icon4}
          ></img>
          <div className="visionprogramme__subsection__heading">Patriotism</div>
          This vital area of focus includes education in citizenship, civic
          consciousness, fostering pride in being Indian and vital national
          concerns such as unity in diversity. It aims at creating dedicated and
          committed citizens who take real pride in serving the nation.
        </div>
      </div>
    </Layout>
  );
}
